import React from "react";
import { addToast } from "@heroui/react";

export function notify({
  content,
  setCopied,
}: {
  content: string;
  setCopied: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  addToast({
    title: "Success",
    description: "Copied to clipboard!",
    severity: "success",
  });
  setCopied(true);
  navigator.clipboard.writeText(content);
  setTimeout(() => setCopied(false), 2000);
}
